import { api } from "index";

const AuthCodes = {
    404: "User not found",
    401: "Invalid credentials",
};

function errorHandler(namespace: string, e: any) {
    const code = e as keyof typeof AuthCodes;

    if (AuthCodes.hasOwnProperty(code)) {
        throw AuthCodes[code];
    }

    console.error(namespace, e.response);
}

// Requests
export async function login(json: LoginParams) {
    try {
        const params = new URLSearchParams();
        params.append("username", json.username);
        params.append("password", json.password);

        const response = await api.post<LoginResponse>(`/auth/token`, params);

        if (!response.data) {
            throw response.request.status;
        }

        return response.data.access_token;
    } catch (e: any) {
        errorHandler("LOGIN", e);
    }
}

export async function checkAuth() {
    try {
        const response = await api.get<ApiResponse<User>>(`/users/me`);

        if (!response.data) {
            throw response.request.status;
        }

        return response.data.data;
    } catch (e: any) {
        errorHandler("[CHECK_AUTH]", e);
    }
}
