import React, {useState} from 'react';
import {Button, Col, DatePicker, Dropdown, Input, Row, Table} from "antd";
import useLaboratoryCurrentData from "./hooks/useLaboratoryCurrentData";
import Icon from "@ant-design/icons";
import {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";

const {RangePicker} = DatePicker;

const LaboratoryCurrentTVTTable = () => {

  const {
    getProductsFilterItems,
    handleRangeChangeTable,
    selectedSeasonType,
    setSelectedSeasonType,
    seasonTypes,
    getStatusFilterItems,
    getGenderFilterItems,
    setSaleDate,
    isLoading,
    data
  } = useLaboratoryCurrentData()

  const columns = [
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      width: "5%",
      render: (text: any, record: any) => {
        return (
          <div
            style={{textAlign: "center"}}
          >
            <img
              src={text}
              style={{width: "90px", height: '120px'}}
            />{" "}
          </div>
        );
      },
    },
    {
      title: "Контакты фабрики",
      dataIndex: "fabric_contacts",
      key: "fabric_contacts",
      width: "9%",
    },
    {
      title: "Артикул поставщика",
      dataIndex: "supplier_article",
      key: "supplier_article",
      width: "9%",
    },
    {
      title: "Категория",
      dataIndex: "category",
      key: "category",
      width: "5%",
    },
    {
      title: "Цвет",
      dataIndex: "color",
      key: "color",
      width: "5%",
    },
    {
      title: "Цена с Налогом",
      dataIndex: "price_with_nds",
      key: "price_with_nds",
      width: "5%",
    },
    {
      title: "Рекоменду-\n" +
        "емая цена \n" +
        "до СПП",
      dataIndex: "recommended_price_do_spp",
      key: "recommended_price_do_spp",
      width: "11%",
    },
    {
      title: "Ср. кол-во\n" +
        "заказов \n" +
        "в день",
      dataIndex: "average_sales_per_day",
      key: "average_sales_per_day",
      width: "9%",
    },
    {
      title: "Рекоменду-\n" +
        "емое кол-во \n" +
        "к заказу",
      dataIndex: "recommended_count_to_offer",
      key: "recommended_count_to_offer",
      width: "11%",
    },
    {
      title: "Стоимость ед товара\n" +
        "с учетом закупа, \n" +
        "растоможки, логистики",
      dataIndex: "price_per_pc",
      key: "price_per_pc",
      width: "12%",
    },
    {
      title: "График",
      dataIndex: "graph",
      key: "graph",
      width: "15%",
      render: (text: any, record: any) => {
        return (
          <div
            style={{textAlign: "center"}}
          >
            <img
              src={text}
              style={{width: "90px", height: '120px'}}
            />{" "}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row style={{paddingTop: 20, backgroundColor: '#f8f8f8'}}>
        {seasonTypes.map((item, index) =>
          <Col style={{width: '25%'}}>
            <Button
              className={
                item.id === selectedSeasonType.id
                  ? "table-tab-button-active-without-circle"
                  : "table-tab-button-without-circle"
              }
              style={{
                borderTopLeftRadius: index === 0 ? '10px' : '0',
                borderTopRightRadius: index === 3 ? '10px' : '0'
              }}
              onClick={() => setSelectedSeasonType(item)}
            >
              {item.ru}
            </Button>
          </Col>
        )
        }
      </Row>

      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getProductsFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Предмет</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getStatusFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Статус</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{width: "150px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getGenderFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Пол</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{marginLeft: 10, marginTop: 3}}>
          <RangePicker
            showTime
            onChange={handleRangeChangeTable}
            placeholder={["Начала создания ТВТ", "Окончания создания ТВТ"]}
            style={{width: "350px"}}
            format={"YYYY-MM-DD HH:mm:ss"}
          />
        </Col>
        <Col style={{marginLeft: 10, marginTop: 3}}>
          <DatePicker
            showTime
            placeholder={"Начало продаж"}
            format={"DD.MM.YYYY HH:mm"}
            style={{width: "100%"}}
            onChange={(e) => setSaleDate(e)}
          />
        </Col>
      </Row>


      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col span={24}>
          <Table
            loading={isLoading}
            className="product-arrival-table"
            columns={columns}
            dataSource={data}
            scroll={{x: true}}
            pagination={false}
            // pagination={{
            //   onChange: (page, pageSize) => setCurrentPage(page),
            //   position: ["bottomCenter"],
            //   pageSize: Number(currentPageSize),
            //   total: Number(data?.total),
            //   showSizeChanger: false,
            //   current: currentPage,
            // }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LaboratoryCurrentTVTTable;
