import {api} from "index";

export async function getProductsData(
    page?: string,
    pageSize?: string,
    sellers?: string[],
    analytics?: string[],
    searchText?: string,
) {
    let params = new URLSearchParams();
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }
    if (sellers && sellers.length > 0) {
        params.append("sellers", sellers?.map((item: any) => item?.id).join(","));
    }
    if (analytics && analytics.length > 0) {
        params.append("analytics", analytics.map((item: any) => item?.id).join(","));
    }
    if (searchText) {
        params.append("search", searchText);
    }

    const response = await api.get<ApiResponseList<Products>>(`/productsV2`, {
        params,
    });
    return response.data.data;
}

export async function getProductsDataById(
    id: string,
) {
    const response = await api.get(`/productsV2/${id}`);
    return response.data.data;
}

export async function createProductsUserAnalytics(json: any) {
    const response = await api.post<any>(`/product-analytics`, json);
    return response.data;
}

export async function createProductsKeyWordByWord(json: any) {
    const response = await api.post<any>(`/keywords-products`, json);
    return response.data;
}

export async function createAutoProductsKeyWordByWord(json: any) {

    const {
        keywords,
        productId
    } = json

    const response = await api.post<any>(`/keywordsV2/product/${productId}/list`, {keywords: keywords});
    return response.data;
}

export async function postMinusPhrase(json: any) {

    const {
        keywordIds,
        productId
    } = json

    const response = await api.post<any>(`/keywordsV2/product/${productId}/setMinusPhrases`, {keywordIds: keywordIds});
    return response.data;
}

export async function createProductsKeyWordByWordGroup(json: any) {
    const response = await api.post<any>(`/keywords-products/group`, json);
    return response.data;
}

export async function deleteProductsKeyWord(data: any) {

    const {id, productId} = data
    const response = await api.delete<any>(`/keywords-products/${productId}/${id}`);
    return response.data;
}

export async function deleteProductsUserAnalytics(data: any) {

    const {analyticId, productId} = data

    if (analyticId && productId){
        const response = await api.delete(`/product-analytics/${productId}/${analyticId}`);
        return response.data;
    }
}

//coefficients

export async function getProductCoefficientsById(
    id: string,
) {
    const response = await api.get(`/product-sales-ratios/${id}`);
    return response.data.data;
}

export async function updateProductCoefficientsById(params: any) {
    const {id, ...body} = params;
    const response = await api.patch<any>(`/product-sales-ratios/${params.id}`, body);
    return response.data;
}

export async function getProducts(
    search?: string,
    notEmptyMode?: string,
) {

    let params = new URLSearchParams();
    if (search) {
        params.append("search", search);
    }
    if (notEmptyMode) {
        params.append("not_empty", notEmptyMode);
    }

    const response = await api.get<any>(`/categories/list`, {params});
    return response.data.data;
}

export async function getKeywordsByProductArticle(article?: string) {

    const response = await api.get<any>(
        `/keywords-positions/byArticule/${article}/keywords`
    );

    return response.data.data;
}

export async function getKeywordsByProductArticleV2(article?: string) {

    const response = await api.get<any>(
        `/keywords-products/getByArticule/${article}/keywords`
    );

    return response.data.data;
}

export async function getProductsGroupData(
    searchValue?: string,
    page?: string,
    pageSize?: string,
) {
    let params = new URLSearchParams();

    if (searchValue) {
        params.append("search", searchValue);
    }
    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (page) {
        params.append("page", page);
    }

    const response = await api.get<any>(`/product-groups`, {
        params,
    });

    return response.data.data;
}