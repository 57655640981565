import axios from "axios";
import {getToken, removeToken} from "utils/tokenStorage";

import * as auth from "./auth";
import * as user from "./user";
import * as financialResult from "./financial-result";
import * as role from "./role";
import * as seller from "./seller";
import * as analytics from "./analytics";
import * as categories from "./categories";
import * as sizes from "./sizes";
import * as advertisingMirror from "./advertisingMirror";
import * as product from "./product";
import * as analyticUser from "./analyticUser";
import * as keyWords from "./keyWords";
import * as coordinatorUser from "./coordinatorUser";
import * as expenses from "./expenses";
import * as storage from "./storage";
import * as operatingExpenses from "./operatingExpenses";
import * as costPrice from "./costPrice";
import * as documentMirror from "./documentMirror";
import * as questionsAndAnswers from "./questionsAndAnswers";
import * as chat from "./chat";
import * as shipments from "./shipments";
import * as laboratory from "./laboratory";

export const createApi = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": true,
            Accept: "application/json, application/x-www-form-urlencoded",
        },
    });

    instance.interceptors.request.use((config) => {
        const token = getToken();
        if (token) {
            (config.headers as any)["Authorization"] = `Bearer ${token}`;
        }

        return config;
    });

    instance.interceptors.response.use(
        (res) => res,
        (error) => {
            if (error.response.status === 401) {
                removeToken();
                if (error.config?.url !== "/auth/token") {
                    //if sent request is not login
                    window.location.reload(); //then reload so that user can login again
                }
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export const backend = {
    auth,
    user,
    analyticUser,
    coordinatorUser,
    keyWords,
    financialResult,
    role,
    seller,
    analytics,
    categories,
    sizes,
    product,
    advertisingMirror,
    expenses,
    storage,
    operatingExpenses,
    costPrice,
    questionsAndAnswers,
    chat,
    documentMirror,
    shipments,
    laboratory
};
