import {useQuery} from "react-query";
import {backend} from "api";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import {Checkbox, Input, MenuProps} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "assets/images/search-icon.svg";
import useUserListByRole from "../../users/hooks/useUserListByRole";
import useSellerList from "../../sellers/hooks/useSellerList";

export default function useProducData() {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<string>("10");
    const [searchText, setSearchText] = useState<string>("");
    const [selectedAnalyticUser, setSelectedAnalyticUser] = useState<Array<string>>([]);
    const [selectedSeller, setSelectedSeller] = useState<Array<string>>([]);
    const [debouncedSearch] = useDebounce(searchText, 1000);

    const {
        sellers,
        search: searchSellers,
        setSearch: setSearchSellers,
    } = useSellerList();

    const {
        users,
        setRoleId,
        searchValue: searchUser,
        setSearchValue: setSearchUser,
    } = useUserListByRole();

    useEffect(() => {
        setRoleId(3)
    },[])

    const getSellersFilterItems = () => {
        if (sellers?.length === 0 || !sellers) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        sellers?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.company_name}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item.company_name}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item.id}`,
                });
            }
        });

        selectedSeller?.forEach((item: any) => {
            const isDuplicate = selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.company_name}
                                checked={selectedSeller.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedSeller = [...selectedSeller];
                                    if (e.target.checked) {
                                        newSelectedSeller.push(item);
                                    } else {
                                        newSelectedSeller = newSelectedSeller.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedSeller(newSelectedSeller);
                                }}
                            >
                                {item.company_name}
                            </Checkbox>
                        </div>
                    ),
                    key: `seller-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchSellers}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchSellers(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "product-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const getAnalyticsUsersFilterItems = () => {
        if (!users) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        users?.forEach((item: any) => {
            const isDuplicate = selectedAnalyticUser.some((userItem: any) => userItem?.id?.toString() === item?.id?.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.firstName}
                                checked={selectedAnalyticUser.some((userItem: any) => userItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedUser = [...selectedAnalyticUser];
                                    if (e.target.checked) {
                                        newSelectedUser.push(item);
                                    } else {
                                        newSelectedUser = newSelectedUser.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedAnalyticUser(newSelectedUser);
                                }}
                            >
                                {item.firstName}
                            </Checkbox>
                        </div>
                    ),
                    key: `user-${item.id}`,
                });
            }
        });

        selectedAnalyticUser?.forEach((item: any) => {
            const isDuplicate = selectedAnalyticUser.some((userItem: any) => userItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{ display: "flex", gap: "10px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.firstName}
                                checked={selectedAnalyticUser.some((userItem: any) => userItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedUser = [...selectedAnalyticUser];
                                    if (e.target.checked) {
                                        newSelectedUser.push(item);
                                    } else {
                                        newSelectedUser = newSelectedUser.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedAnalyticUser(newSelectedUser);
                                }}
                            >
                                {item.firstName}
                            </Checkbox>
                        </div>
                    ),
                    key: `user-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchUser}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchUser(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "user-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    const {data: products, ...options} = useQuery(
        [
            "PRODUCTS_ALL_DATA",
            currentPage,
            currentPageSize,
            selectedSeller,
            selectedAnalyticUser,
            debouncedSearch
        ],
        () =>
            backend.product.getProductsData(
                currentPage.toString(),
                currentPageSize,
                selectedSeller,
                selectedAnalyticUser,
                debouncedSearch
            ),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );


    return {
        products,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchText,
        setSearchText,
        getSellersFilterItems,
        getAnalyticsUsersFilterItems,
        ...options,
    };
}
