import React, {useState} from "react";
import {Button, Col, Dropdown, Input, MenuProps, message, Modal, Pagination, Row, Select, Spin} from "antd";
import Icon, {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import selectIcon, {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import useProducData from "./hooks/useProducData";
import {ReactComponent as editIcon} from "../../assets/images/big-edit-icon.svg";
import EditProductAnalytics from "./modal/EditProductAnalytics";
import {CopyToClipboard} from "react-copy-to-clipboard";
import EditProductKeyWords from "./modal/EditProductKeyWords";
import {Link, useLocation} from 'react-router-dom';
import useAnalyticsUserList from "../analyticsUser/hooks/useAnalyticsUserList";
import EditKeyWords from "./modal/EditKeyWords";
import useCoordinatorsUserList from "../coordinatorUsers/hooks/useCoordinatorsUserList";
import EditCoordinatorAnalytics from "./modal/EditCoordinatorAnalytics";

export default function ProductsCoordinatorsTable() {

    const location = useLocation();

    const {
        coordinatorUser,
        currentPage,
        setCurrentPage,
        currentPageSize,
        setCurrentPageSize,
        searchName,
        setSearchName,
        isLoading,
    } = useCoordinatorsUserList()

    const [editKeyWordsModal, setEditKeyWordsModal] = useState<any>(false);

    const [editCoordinatorAnalytics, setEditCoordinatorAnalytics] = useState<any>({
        coordinatorId: '',
        isOpen: false
    });

    const productsItemsForEdit: MenuProps["items"] = [
        {
            label: (
                <span style={{display: "flex", gap: "10px"}}>
                    <Icon component={editIcon} style={{cursor: "pointer"}}/>
                    Редактировать аналитика
                </span>
            ),
            key: "EDIT_COORDINATOR_USER_ANALYTICS",
        }
    ];

    const getProductsActions = (coordinatorId: any) => {
        return {
            items: productsItemsForEdit,
            onClick: ({item, key, keyPath, domEvent}: any) => {
                switch (key) {
                    case "EDIT_COORDINATOR_USER_ANALYTICS":
                        setEditCoordinatorAnalytics({
                            ...editCoordinatorAnalytics,
                            coordinatorId: coordinatorId,
                            isOpen: true
                        })
                        break;
                }
            },
        };
    };

    return (
        <div style={{
            backgroundColor: '#f8f8f8',
        }}>
            <Row gutter={6} align={"middle"} style={{
                backgroundColor: '#f8f8f8',
            }}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        className="table-header-input"
                        placeholder={"Поиск имени, телефону и telegram"}
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        // type={"number"}
                    />
                </Col>


                {/*<Col style={{width: "110px", paddingTop: 0, paddingBottom: 0}}>*/}
                {/*    <Dropdown*/}
                {/*        trigger={["click"]}*/}
                {/*        placement={"bottomRight"}*/}
                {/*        menu={{items: getSellersFilterItems()}}*/}
                {/*        overlayClassName={"dropdown-border"}*/}
                {/*    >*/}
                {/*        <div className={"analytics-header-dropdown "}>*/}
                {/*            <div>Юр. лицо</div>*/}
                {/*            <Icon*/}
                {/*                component={SelectIconComponent}*/}
                {/*                style={{marginTop: "2px", fontSize: "10px"}}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Dropdown>*/}
                {/*</Col>*/}
                {/*<Col style={{width: "110px"}}>*/}
                {/*    <Dropdown*/}
                {/*        trigger={["click"]}*/}
                {/*        placement={"bottomRight"}*/}
                {/*        menu={{items: getAnalyticsUsersFilterItems()}}*/}
                {/*        overlayClassName={"dropdown-border"}*/}
                {/*    >*/}
                {/*        <div className={"analytics-header-dropdown "}>*/}
                {/*            <div>Аналитик</div>*/}
                {/*            <Icon*/}
                {/*                component={SelectIconComponent}*/}
                {/*                style={{marginTop: "2px", fontSize: "10px"}}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </Dropdown>*/}
                {/*</Col>*/}

                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select"
                        style={{
                            borderTopRightRadius: 8
                        }}
                        id="analytics-select"
                        suffixIcon={<img src={selectIcon} alt={""}/>}
                        defaultValue={currentPageSize}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
            </Row>
            <Row className="container" style={{marginTop: "10px", width: '100%'}}>
                <Col span={24} style={{
                    padding: '10px 10px'
                }}>
                    <div className="product-extra-nav">
                        <div className="product-extra-nav-items">
                            <div
                                className={`product-extra-nav-item ${location?.pathname.includes('products/products') && 'product-extra-nav-item-active'}`}>
                                <Link to={'/products/products'}>
                                    Товары
                                </Link>
                            </div>
                            <div
                                className={`product-extra-nav-item ${location?.pathname.includes('products/analytics') && 'product-extra-nav-item-active'}`}>
                                <Link to={'/products/analytics'}>
                                    Координаторы и аналитики
                                </Link>
                            </div>
                        </div>
                        <div>
                            <Button
                                className={"button"}
                                type={"primary"}
                                style={{width: "300px", fontSize: "12px"}}
                                onClick={() => setEditKeyWordsModal(true)}
                            >
                                Дополнить справочник ключевых слов
                            </Button>
                        </div>

                    </div>

                </Col>

                <Col span={24} style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }}>
                    {
                        isLoading
                            ?
                            <div
                                style={{
                                    padding: "150px",
                                    textAlign: "center",
                                }}
                            >
                                <Spin
                                    indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                                />
                            </div>
                            : !coordinatorUser?.data
                            ? (
                                <span>Нет информации по выбранному промежутку</span>
                            )
                            : (
                                <>
                                    <div className="table-container">
                                        <table className={"kiz-table"}>
                                            <thead>
                                            <th style={{}}>Имя координатора</th>
                                            <th style={{}}>Телефон</th>
                                            <th style={{}}>Telegram</th>
                                            <th style={{}}>Закрепеленный аналитик</th>
                                            <th style={{borderTopRightRadius: 8}}>
                                            </th>
                                            </thead>
                                            <tbody>
                                            {coordinatorUser?.data?.map((item: any) => (
                                                <tr>
                                                    <td style={{}}>
                                                        {item?.user?.firstName || '---'}
                                                    </td>
                                                    <td style={{}}>
                                                        {item?.user?.phone || '---'}
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        {item?.user?.tg || '---'}
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        {
                                                            item?.analytics?.map((item: any) => item?.user?.firstName).join(', ') || '---'
                                                        }
                                                    </td>
                                                    <td style={{
                                                        maxWidth: 50,
                                                    }}>
                                                        <Dropdown
                                                            trigger={["click"]}
                                                            placement={"bottomRight"}
                                                            menu={getProductsActions(item?.id)}
                                                        >
                                                            <MoreOutlined
                                                                style={{cursor: "pointer", fontSize: "20px"}}/>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        onChange={(page, pageSize): any => setCurrentPage(page)}
                                        defaultCurrent={1}
                                        current={currentPage}
                                        pageSize={parseInt(currentPageSize)}
                                        total={coordinatorUser.total}
                                        style={{
                                            textAlign: "center",
                                            padding: "16px 0",
                                        }}
                                        showSizeChanger={false}
                                    />
                                </>
                            )
                    }
                </Col>
            </Row>
            <Modal
                open={editKeyWordsModal}
                closable={false}
                footer={null}
                width={700}
            >
                <EditKeyWords
                    keyWordModal={editKeyWordsModal}
                    onClose={() => setEditKeyWordsModal(false)}
                />
            </Modal>
            <Modal
                open={editCoordinatorAnalytics.isOpen}
                closable={false}
                footer={null}
                width={519}
            >
                <EditCoordinatorAnalytics
                    coordinatorId={editCoordinatorAnalytics.coordinatorId}
                    onClose={() => setEditCoordinatorAnalytics({...editCoordinatorAnalytics, isOpen: false})}
                />
            </Modal>
        </div>
    );
}


